$(document).ready(function() {

    // ----------- SHOW :HOVER, :ACTIVE, AND :FOCUS STYLES ON iOS ----------- //

    $(document).on("touchstart", function() {});


    // ---------------------- SVG IMAGES TO INLINE SVGs ---------------------- //

    $("img.svg-img").each(function() {
        var $img = $(this);
        var img_url = $img.attr("src");
        var img_class = $img.attr("class");
        var img_id = $img.attr("id");
        var img_style = $img.attr("style");
        var aspect_ratio = $img.data("aspect-ratio");

        $.get(img_url, function(data) {
            var $div = $('<div>').addClass(img_class);
            if (img_id) {
                $div.attr("id", img_id);
            }
            if (img_style) {
                $div.attr("style", img_style);
            }
            var $svg = $(data).find("svg");
            var $ratio = $('<div class="svg-img-ratio">');
            if (!aspect_ratio) {
                var view_box = $svg.attr("viewBox").split(" ");
                aspect_ratio = view_box[2] / view_box[3];
            }
            $ratio.css("padding-bottom", (1 / aspect_ratio * 100) + "%");
            $div.append($ratio);
            var $data = $('<div class="svg-img-data">').append($svg);
            $div.append($data);
            $img.replaceWith($div);
        }, "xml");
    });


    // ----------------------------- FOCUSPOINT ----------------------------- //

    $(".focuspoint").focusPoint().addClass("ready");


    // ------------------------------ DROPDOWNS ------------------------------ //

    /*
    $(".dropdown").each(function() {
        var $dropdown = $(this);
        var $button = $dropdown.find(".button");
        var $title = $button.find(".dropdown-title");
        var $menu = $dropdown.find(".dropdown-menu");
        var $options = $menu.find("li");

        var menu_toggle = function() {
            $menu.queue(function(next) {
                if ($dropdown.hasClass("open")) {
                    $menu.slideUp();
                    $dropdown.removeClass("open");
                }
                else {
                    $menu.slideDown();
                    $dropdown.addClass("open");
                }
                next();
            });
        };

        $button.click(function() {
            menu_toggle();
        });

        $menu.find("a").click(function() {
            var $option = $(this).closest($options);
            var value = $option.data("value");
            $title.html($option.find(".dropdown-option-title").html());
            $dropdown.trigger("dropdown:change", [value]);
            menu_toggle();
        });

        // Click outside the menu when it's open
        $(document).on("click touchstart", function(e) {
            var $target = $(e.target);
            if ($dropdown.hasClass("open") && $target.closest($dropdown).length === 0) {
                menu_toggle();
            }
        });
    });
    */


    // --------------------------- HAMBURGER MENU --------------------------- //

    $("header").each(function() {
        var $header = $(this);
        var $nav = $header.find("nav");
        var $hamburger = $header.find("a.hamburger");

        $hamburger.click(function() {
            $header.toggleClass("nav-visible");
        });

        // Click outside the nav when it's open
        $(document).on("click touchstart", function(e) {
            var $target = $(e.target);
            if ($header.hasClass("nav-visible") && $target.closest($nav).length === 0 && $target.closest($hamburger).length === 0) {
                $header.removeClass("nav-visible");
            }
        });
    });


    // ----------------------------- SLIDESHOW ----------------------------- //

    $(".section-slideshow").each(function() {
        var $slideshow = $(this);
        var animation_duration = 500;
        var interval_duration = 4000;
        var animating = false;
        var interval = null;
        var hover = false;
        var $slides = $slideshow.find(".slideshow-slides").children();
        var $arrows = $slideshow.find(".slideshow-arrow");
        var $nav = $slideshow.find(".slideshow-nav");
        var $nav_bullets = $();

        var get_active_slide = function() {
            return $slides.filter(".active");
        };

        var go_to_slide = function($slide, slide_from) {
            var $old_slide = get_active_slide();
            if (animating || $old_slide.index() === $slide.index()) {
                return;
            }
            animating = true;
            if (typeof slide_from === "undefined") {
                slide_from = ($old_slide.index() > $slide.index() ? "left" : "right");
            }
            $slide.add($old_slide).addClass("animating");
            TweenLite.fromTo($old_slide, animation_duration / 1000, {x: "0%"}, {x: (slide_from === "left" ? "" : "-") + "100%", ease: Power1.easeInOut});
            TweenLite.fromTo($slide, animation_duration / 1000, {x: (slide_from === "left" ? "-" : "") + "100%"}, {x: "0%", ease: Power1.easeInOut, onComplete: function() {
                $old_slide.removeClass("active");
                $slide.addClass("active");
                $slide.add($old_slide).removeClass("animating");
                animating = false;
            }});
            $slide.find(".focuspoint").focusPoint("adjustFocus");
            $nav_bullets.removeClass("active");
            $nav_bullets.eq($slide.index()).addClass("active");
            set_interval();
        };

        var go_to_next_slide = function() {
            var $active_slide = get_active_slide();
            var $next_slide = $active_slide.next();
            if ($next_slide.length === 0) {
                $next_slide = $slides.first();
            }
            go_to_slide($next_slide, "right");
        };

        var go_to_previous_slide = function() {
            var $active_slide = get_active_slide();
            var $previous_slide = $active_slide.prev();
            if ($previous_slide.length === 0) {
                $previous_slide = $slides.last();
            }
            go_to_slide($previous_slide, "left");
        };

        var clear_interval = function() {
            clearInterval(interval);
        };

        var set_interval = function() {
            clear_interval();
            if (!hover) {
                interval = setInterval(go_to_next_slide, interval_duration);
            }
        };

        // Make first slide active
        $slides.first().addClass("active");

        // Add bullets and hide arrows based on number of slides
        if ($slides.length > 1) {
            $slides.each(function() {
                $nav_bullets = $nav_bullets.add($("<li>"));
            });
            $nav.append($("<ul>").append($nav_bullets));
            $nav_bullets.first().addClass("active");
        }
        else {
            $arrows.hide();
        }

        // Click on arrows
        $arrows.click(function() {
            if ($(this).hasClass("left")) {
                go_to_previous_slide();
            }
            else {
                go_to_next_slide();
            }
        });

        // Click on bullets
        $nav_bullets.click(function() {
            go_to_slide($slides.eq($(this).index()));
        });

        // Set interval
        set_interval();

        // Clear/reset interval on mouse enter/leave
        $slideshow.mouseenter(function() {
            hover = true;
            clear_interval();
        });
        $slideshow.mouseleave(function() {
            hover = false;
            set_interval();
        });

    });


    // ---------------------------- SCROLLMAGIC ---------------------------- //

    var scrollmagic_controller = new ScrollMagic.Controller();
    TweenLite.defaultEase = Linear.easeNone;

    $("header.featured").each(function() {
        var $header = $(this);
        var scene = new ScrollMagic.Scene({duration: 200, tweenChanges: true});
        scene.setTween(
            (new TimelineMax()).add(
                TweenMax.to($header.find(".logo").get(0), 0.3, {scale: 1, y: "0rem", ease: Sine.easeInOut}),
                0
            ).add(
                TweenMax.to($header.find(".background").get(0), 0.3, {opacity: 1, ease: Sine.easeInOut}),
                0.13
            )
        );
        scene.addTo(scrollmagic_controller);
    });

    $("ul.productions-grid").each(function() {
        var $container = $(this);
        var scene = new ScrollMagic.Scene({triggerElement: $container.get(0), duration: 0, triggerHook: "onEnter", offset: 50, reverse: false});
        scene.setTween(
            (new TimelineLite()).staggerFromTo($container.children(), 0.3, {opacity: 0, scale: 1.1}, {opacity: 1, scale: 1, ease: Cubic.easeOut, delay: 0.4}, 0.1)
        );
        scene.addTo(scrollmagic_controller);
    });

    /*
    $("ul.services-list").each(function() {
        var $container = $(this);
        var scene = new ScrollMagic.Scene({triggerElement: $container.get(0), duration: 0, triggerHook: "onEnter", offset: 100, reverse: false});
        scene.setTween(
            (new TimelineLite()).staggerFromTo($container.children(), 0.5, {x: "-100%"}, {x: "0%", ease: Back.easeOut, delay: 0.2}, 0.1)
        );
        scene.addTo(scrollmagic_controller);
    });
    */

    $("ul.team-grid").each(function() {
        var $container = $(this);
        var $children = $container.children();
        var children = $children.toArray();
        children.sort(function() { return 0.5 - Math.random(); });
        var scene = new ScrollMagic.Scene({triggerElement: $container.get(0), duration: 0, triggerHook: "onEnter", offset: 100, reverse: false});
        scene.setTween(
            (new TimelineLite()).staggerFromTo(children, 0.2, {opacity: 0}, {opacity: 1}, 0.05)
        );
        scene.addTo(scrollmagic_controller);
    });

});
